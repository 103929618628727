import { gsap } from "gsap";
import { RoughEase } from "gsap/EasePack";

gsap.registerPlugin(RoughEase);

function AnimMunch() {
  const munchings = `<svg class="anim-munch" fill="#0d242f" viewBox="0 0 500 700" preserveAspectRatio="xMaxYMax slice">
  <g class="munches" opacity="0">
  <circle cx="445.25" cy="451.18" r="85.09" fill="#0d242f" fill-opacity="0"/>
  <circle cx="329.67" cy="719.83" r="85.09" fill="#0d242f"/>
  <circle cx="355.92" cy="626.35" r="85.09" fill="#0d242f"/>
  <circle cx="448.64" cy="545.15" r="85.09" fill="#0d242f"/>
  <circle cx="216.16" cy="662.62" r="85.09" fill="#0d242f"/>
  <circle cx="324.25" cy="577.53" r="85.09" fill="#0d242f"/>
  <circle cx="530.33" cy="480.12" r="85.09" fill="#0d242f"/>
  <circle cx="389.34" cy="480.12" r="85.09" fill="#0d242f"/>
  <g>
    <circle cx="117.05" cy="658" r="85.09" fill="#0d242f"/>
    <circle cx="216.16" cy="615.18" r="85.09" fill="#0d242f"/>
  </g>
  <circle cx="282.7" cy="511.08" r="85.09" fill="#0d242f"/>
  <circle cx="484.68" cy="431.84" r="85.09" fill="#0d242f"/>
  <circle cx="45.99" cy="694.42" r="85.09" fill="#0d242f"/>
  <circle cx="26.88" cy="621.58" r="85.09" fill="#0d242f"/>
  <circle cx="161.1" cy="587.69" r="85.09" fill="#0d242f"/>
  <circle cx="363.55" cy="425.99" r="85.09" fill="#0d242f"/>
  <circle cx="211.92" cy="502.61" r="85.09" fill="#0d242f"/>
  <circle cx="476.59" cy="353.06" r="85.09" fill="#0d242f"/>
  <circle cx="76.01" cy="541.27" r="85.09" fill="#0d242f"/>
  <circle cx="276.3" cy="403.04" r="85.09" fill="#0d242f"/>
  <g>
    <circle cx="367.78" cy="310.79" r="85.09" fill="#0d242f"/>
    <circle cx="465.66" cy="255.82" r="85.09" fill="#0d242f"/>
  </g>
  <circle cx="42.98" cy="466.56" r="85.09" fill="#0d242f"/>
  <circle cx="155.17" cy="438.15" r="85.09" fill="#0d242f"/>
  <circle cx="224.63" cy="324.26" r="85.09" fill="#0d242f"/>
  <circle cx="336.82" cy="250.57" r="85.09" fill="#0d242f"/>
  <circle cx="70.08" cy="359.92" r="85.09" fill="#0d242f"/>
  <circle cx="219.17" cy="246.33" r="85.09" fill="#0d242f"/>
  <g>
    <circle cx="111.97" cy="279.37" r="85.09" fill="#0d242f"/>
    <circle cx="22.27" cy="264.12" r="85.09" fill="#0d242f"/>
  </g>
  <circle cx="453.45" cy="132.53" r="85.09" fill="#0d242f"/>
  <circle cx="337.87" cy="189.74" r="85.09" fill="#0d242f"/>
  <circle cx="364.12" cy="96.26" r="85.09" fill="#0d242f"/>
  <circle cx="453.45" r="85.09" fill="#0d242f"/>
  <circle cx="224.36" cy="132.53" r="85.09" fill="#0d242f"/>
  <circle cx="344.63" cy="36.52" r="85.09" fill="#0d242f"/>
  <circle cx="244.58" cy="-1.47" r="85.09" fill="#0d242f"/>
  <circle cx="111.97" cy="165.48" r="85.09" fill="#0d242f"/>
  <g>
    <circle cx="42.98" cy="143.02" r="85.09" fill="#0d242f"/>
    <circle cx="142.09" cy="100.2" r="85.09" fill="#0d242f"/>
  </g>
  <circle cx="134.08" cy="22.17" r="85.09" fill="#0d242f"/>
  <circle cx="35.72" cy="15.11" r="85.09" fill="#0d242f"/>
</g>
<g class="munch" fill="#0d242f">
  <g>
    <circle cx="463.69" cy="665.57" r="50.71"/>
    <circle cx="422.09" cy="694.53" r="21.75" transform="rotate(-85.27 422.079024 694.529572)"/>
  </g>
  <circle cx="485.53" cy="617.63" r="21.83" transform="rotate(-85.27 485.512819 617.630704)"/>
</g>
<g class="arrow">
  <path fill="none" stroke="#fb6955" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.08" d="M442.59,653.63h19.55m0,0-9.51-9.25m9.51,9.25-9.51,9.25"/>
</g>
</svg>


`;
  const animMunches = gsap.utils.toArray(".anim-munch");
  if (!animMunches.length) return;

  animMunches.forEach((card) => {
    let q = gsap.utils.selector(card);
    card.insertAdjacentHTML("beforeend", munchings);

    card.munches = gsap.timeline({ paused: true });

    card.munches.from(
      q(".munches > *"),
      {
        duration: 0.01,
        opacity: 0,
        stagger: {
          amount: 30,
        },
        ease: "power3.in",
      },
      3
    )
    card.imageTween = gsap.to(q("img"), {
      duration: 0.25,
      rotation: 3,
      paused: true,
      transformOrigin: "center",
      y: "-0.5rem",
    });

    card.addEventListener("mouseenter", (e) => {
      gsap.set(q(".munches"), { opacity: 1 });

      card.munches.timeScale(1).play();
      card.imageTween.play();
    });

    card.addEventListener("mouseleave", (e) => {
      card.munches.seek(0).pause();
      card.imageTween.reverse();
    });
  });
}

export default AnimMunch;
