import { throttle, debounce } from './utils';

let isSticky = false;
let header;

function StickyHeader() {
  header = document.querySelector('.c-header');
  if (!header) return;

  const siblingTrigger = header.querySelector('#siblings-trigger');
  const siblings = header.querySelector('#siblings');
  const logo = header.querySelector('.c-header__logo-wrapper');
  if (siblingTrigger && siblings) {
    Siblings(siblingTrigger, siblings, logo);
  }

  const siblingTriggerMobile = header.querySelector('#siblings-trigger-mobile');
  const siblingsMobile = header.querySelector('#siblings-mobile');
  if (siblingTriggerMobile && siblingsMobile) {
    Siblings(siblingTriggerMobile, siblingsMobile);
  }

  OnScroll();
  window.addEventListener('scroll', throttle(OnScroll, 100));
  window.addEventListener('scroll', debounce(OnScroll, 100));
}

function OnScroll() {
  const matches = header.getBoundingClientRect().top === 0;

  if (!isSticky && matches) {
    isSticky = true;
    document.body.classList.add('is-sticky')
  } else if (isSticky && !matches) {
    isSticky = false;
    document.body.classList.remove('is-sticky')
  }
}

function Siblings(trigger, content, logo) {
  trigger.addEventListener('click', () => {
    const expanded = trigger.getAttribute('aria-expanded') === 'true';
    trigger.setAttribute('aria-expanded', !expanded);
    content.hidden = expanded;

    if (logo) {
      logo.classList.toggle('c-header__logo-wrapper--open', !expanded);
    }
  });
}

export default StickyHeader;
