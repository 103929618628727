import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

function AnimHeroUX() {
  let svg = document.querySelector('.hero-anim--ux');
	let q = gsap.utils.selector(svg);
	let main = gsap.timeline();

	gsap.set('.hero-anim', { autoAlpha: 1 });

	let mm = gsap.matchMedia(),
		breakPoint = 800;

	mm.add(
		{
			isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${
				breakPoint - 1
			}px) and (prefers-reduced-motion: no-preference)`
		},
		(context) => {
			let { isDesktop, isMobile } = context.conditions;

			main
				.add(dropper(), 0)
				.add(intro(), 0.3)
				.add(helix(), 1.5)
				.add(dna(), 2)
				.add(wave().timeScale(1.5), 2)
				.add(snowflakes(), 2.5)
				.add(time(), 1)
				.from(
					q('#sparkles g'),
					{
						transformOrigin: 'center center',
						rotation: 'random([-660, 660])',
						scale: 0,
						ease: 'back.out',
						stagger: 0.1,
						duration: 3
					},
					3
				);

			function intro() {
				let tl = gsap.timeline({ defaults: { duration: 4, ease: 'sine.out' } });

				tl.from(
					q(
						'#drawsvg-intro-top, #drawsvg-intro-underline, #drawsvg-intro-heart'
					),
					{ opacity: 0, duration: 0.01 },
					0
				)
					.from(q('#drawsvg-intro-top'), { drawSVG: '50% 50%' }, 0)
					.from(q('#drawsvg-intro-topleft'), { drawSVG: '0% 0%' }, 0)
					.from(q('#drawsvg-intro-topright'), { drawSVG: '0% 0%' }, 0)
					.from(q('#drawsvg-intro-underline'), { drawSVG: '50% 50%' }, 0)
					.fromTo(
						q('.drawsvg-intro-heart'),
						{ drawSVG: '91% 91%' },
						{ drawSVG: '91% -91%' },
						0
					);

				return tl;
			}

			function snowflakes() {
				let tl = gsap.timeline({ defaults: { duration: 4, ease: 'sine.out' } });

				tl.from(
					q('.snowflake1'),
					{
						opacity: 0,
						scale: 0.3,
						rotation: -100,
						duration: 4,
						ease: 'back.out',
						transformOrigin: '50% 50%'
					},
					0
				)
					.from(
						q('.snowflake2'),
						{
							opacity: 0,
							scale: 0.3,
							rotation: 100,
							duration: 4,
							ease: 'back.out',
							transformOrigin: '50% 50%'
						},
						0
					)
					.from(
						q('#snowflakes .dot'),
						{
							opacity: 0,
							duration: 1,
							ease: 'sine.out',
							transformOrigin: '50% 50%',
							stagger: 0.2
						},
						0
					);

				return tl;
			}

			function dna() {
				let tl = gsap.timeline({ defaults: { duration: 3, ease: 'expo.out' } });

				tl.from(q('#dna-graph'), { duration: 0.7, opacity: 0 }, 0)
					.from(
						q('#dna-graph .one rect'),
						{ scaleY: 0, transformOrigin: '50% 50%' },
						0.4
					)
					.from(q('#dna-graph .one .dot'), { yPercent: 310 }, 0.4)
					.from(q('#dna-graph .one .dot-top'), { yPercent: -310 }, 0.4)

					.from(
						q('#dna-graph .two rect'),
						{ scaleY: 0, transformOrigin: '50% 50%' },
						0.3
					)
					.from(q('#dna-graph .two .dot'), { yPercent: 250 }, 0.3)
					.from(q('#dna-graph .two .dot-top'), { yPercent: -250 }, 0.3)

					.from(
						q('#dna-graph .three rect'),
						{ scaleY: 0, transformOrigin: '50% 50%' },
						0.2
					)
					.from(q('#dna-graph .three .dot'), { yPercent: 180 }, 0.2)
					.from(q('#dna-graph .three .dot-top'), { yPercent: -180 }, 0.2)

					.from(
						q('#dna-graph .four rect'),
						{ scaleY: 0, transformOrigin: '50% 50%' },
						0.1
					)
					.from(q('#dna-graph .four .dot'), { yPercent: 100 }, 0.1)
					.from(q('#dna-graph .four .dot-top'), { yPercent: -100 }, 0.1)

					.from(
						q('#dna-graph .five rect'),
						{ scaleY: 0, transformOrigin: '50% 50%' },
						0
					)
					.from(q('#dna-graph .five .dot'), { yPercent: 180 }, 0)
					.from(q('#dna-graph .five .dot-top'), { yPercent: -180 }, 0);

				return tl;
			}

			function helix() {
				let tl = gsap.timeline({ defaults: { duration: 1, ease: 'sine.out' } });

				tl.from(q('#double-helix > *'), { opacity: 0, duration: 0.01 }, 0)
					.from(q('#double-helix > *'), { drawSVG: '50% 50%' }, 0)
					.from(q('#with-lettering'), { opacity: 0 }, 0)
					.from(q('#vitaly-lettering'), { opacity: 0 }, 0.2);

				return tl;
			}

			function wave() {
				let tl = gsap.timeline({
					defaults: { duration: 1, ease: 'none' },
					paused: true
				});

				tl.from(q('#wave-one'), { drawSVG: '0% 0%' }, 0)
					.from(q('#wave-two'), { drawSVG: '10% 10%' })
					.from(q('#wave-two'), { opacity: 0, duration: 0.01 }, '<')
					.from(q('#wave-three'), { drawSVG: '0% 0%' }, '<+=30%');

				let main = gsap.timeline({ defaults: { duration: 1 } });

				main
					.to(tl, { progress: 1, ease: 'sine.inOut', duration: 4 })
					.from(
						q('#drops > g'),
						{
							yPercent: -50,
							opacity: 0,
							stagger: { from: 'end', each: '0.3' },
							scale: 0,
							transformOrigin: 'top center'
						},
						'<50%'
					)

					.from(
						q('#flower-one .stem'),
						{ scaleY: 0, transformOrigin: 'bottom center' },
						1.1
					)
					.from(
						q('#flower-one .flower'),
						{ scale: 0, transformOrigin: 'center center', rotation: 360 },
						1.5
					)

					.from(
						q('#flower-two .stem'),
						{ scaleY: 0, transformOrigin: 'bottom center' },
						0.5
					)
					.from(
						q('#flower-two .flower'),
						{ scale: 0, transformOrigin: 'center center', rotation: 360 },
						0.9
					)

					.from(
						q('#cog-flowers .dots, #wave #circles'),
						{ opacity: 0, stagger: 0.4 },
						2
					)

					.from(
						q('#fingerprint g > *'),
						{ drawSVG: '0% 0%', stagger: { from: 'end', each: 0.1 } },
						'<'
					)
					.from(q('#fingerprint > path'), { opacity: 0 }, '<30%');

				return main;
			}

			function time() {
				let tl = gsap.timeline({ defaults: { duration: 2, ease: 'back.out' } });

				tl.from(q('#plant'), {
					scale: 0,
					yPercent: 100,
					transformOrigin: 'bottom center',
					ease: 'sine.out'
				})
					.from(
						q('#left-leaf'),
						{ scale: 0, transformOrigin: 'bottom right' },
						0.5
					)
					.from(
						q('#right-leaf'),
						{ scale: 0, transformOrigin: 'bottom left' },
						'<50%'
					)
					.from(
						q('#hourglass'),
						{
							rotation: 180,
							yPercent: -5,
							transformOrigin: 'center center',
							ease: 'back.inOut'
						},
						0
					)
					.from(
						q('#topsand'),
						{
							yPercent: -40,
							scale: 1.1,
							transformOrigin: 'center center',
							duration: 1,
							ease: 'expo.out'
						},
						'<50%'
					)
					.fromTo(
						q('#sand'),
						{ y: -14 },
						{ y: 20, ease: 'sine.out', stagger: 1, duration: 1 },
						2
					)
					.from(
						q('#bottomsand'),
						{ yPercent: 100, duration: 3, ease: 'sine.out' },
						'<20%'
					)
					.fromTo(
						q('#finalsand'),
						{ y: -14 },
						{ y: 0, ease: 'sine.out', stagger: 1, duration: 1 },
						4.5
					);

				return tl;
			}

			function dropper() {
				let tl = gsap.timeline({ defaults: { duration: 1, ease: 'back.out' } });

				tl.to(q('.squeeze'), {
					keyframes: { scale: [1, 0.7, 1], easeEach: 'none' },
					transformOrigin: '20% 90%',
					duration: 2,
					ease: 'back.out'
				})
					.to(
						q('#pipette-filling'),
						{ yPercent: 30, xPercent: -30, ease: 'sine.out', duration: 0.5 },
						0
					)
					.to(
						q('#pipette-filling'),
						{ yPercent: 10, xPercent: -10, ease: 'sine.out', duration: 0.5 },
						0.5
					)
					.fromTo(
						q('#droplet'),
						{ scale: 0.4, yPercent: -50, xPercent: 60, rotation: 50 },
						{
							yPercent: 500,
							xPercent: 0,
							scale: 1,
							rotation: 0,
							transformOrigin: 'top left',
							ease: 'sine.out'
						},
						0.2
					)
					.from(q('#droplet'), { opacity: 0, duration: 0.001 }, '<')
					.from(
						q('#liquid'),
						{ yPercent: 100, rotation: 20, ease: 'sine.out' },
						'<15%'
					)
					.set(q('#droplet'), { scale: 0.3, yPercent: -50, xPercent: 60 })
					.to(q('#droplet'), { scale: 1, yPercent: 0, xPercent: 0 })
					.from(
						q('#glass-pipette'),
						{
							rotation: 20,
							yPercent: -50,
							xPercent: -20,
							transformOrigin: 'bottom left',
							ease: 'elastic.out',
							duration: 3
						},
						0
					);

				return tl;
			}

			function textIntro() {
				let tl = gsap.timeline({ defaults: { duration: 1, ease: 'sine.out' } });

				let tween = gsap.from(q('.intro-text'), {
					opacity: 0,
					yPercent: -10,
					ease: 'sine.out'
				});

				return tl;
			}

			if (isDesktop) {
				//TODO once I have access to the figma to grab the surrounding decor.
				//Whap in some animations on scroll for the decor

				// Forgive me for my sins, cassie
				// https://images.squarespace-cdn.com/content/v1/5a05ececd55b4165f250f032/1542045347481-ET7TCLLBH4WGSY2J7QK6/fa5.jpg

				gsap.to(
					q('.hero-decorations'),
					{
						duration: 1,
						opacity: 1,
						ease: 'none',
					},
					0.3
				)

				gsap.to('.hero-decorations > *', {
					y: 'random([-70, -150, -50])',
					ease: 'none',
					opacity: 0,
					scrollTrigger: {
						trigger: '.c-hero',
						start: 0,
						end: 'bottom top',
						scrub: 1,
					},
				});
			} else {
				// lil viewport, no decor animations
			}
		}
	);
}

export default AnimHeroUX;
