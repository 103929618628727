import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimCards() {
  const cards = gsap.utils.toArray('.anim-card');

  if (!cards.length > 0) return;

  cards.forEach((card) => {
    const q = gsap.utils.selector(card);

    let tl = gsap.timeline({scrollTrigger: {
      trigger: card,
      start: 'center 80%',
      end: 'bottom top',
    }})

    tl.from(q('.color'), {
      opacity: 0,
      duration: 4,
      ease: "circ.out",
    })
  });
}

export default AnimCards;
