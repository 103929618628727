import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimTestimonials() {
  const svg = document.querySelector('.testimonials__svg');

  if (!svg) return;

  const q = gsap.utils.selector('.testimonials__svg');

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.testimonials__svg',
      start: 'top 70%',
      end: 'top 30%',
      scrub: 2,
    },
  });

  tl.from(q('.leaf'), {
    duration: 4,
    rotate: -50,
    svgOrigin: '60 60',
    ease: 'circ.out',
  })
  tl.from(q('.sparks > *'), {
    opacity: 0,
    scale: 0,
    transformOrigin: 'center'
  }, 0);
}

export default AnimTestimonials;
