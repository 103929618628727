import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { CustomWiggle } from 'gsap/CustomWiggle';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, CustomEase, CustomWiggle);

function AnimHero() {
  const svg = document.querySelector('.hero-anim');
  if (!svg) return;
  const animationIsOk = window.matchMedia(
    '(prefers-reduced-motion: no-preference)'
  ).matches;

  CustomWiggle.create('bottleShake', {
    wiggles: 12,
    type: 'easeInOut',
  });

  CustomWiggle.create('prawnWiggle', {
    wiggles: 4,
    type: 'easeInOut',
  });

  const dt = document.querySelector('.hero-anim--desktop');
  const mob = document.querySelector('.hero-anim--mobile');
  let q;

  if (window.matchMedia('(min-width: 1000px)').matches) {
    q = gsap.utils.selector(dt);
  } else {
    q = gsap.utils.selector(mob);
  }


  if (animationIsOk) {
    let desktopTl = gsap.timeline({ defaults: { ease: 'sine.out' } });
    let mobileTl = gsap.timeline({ defaults: { ease: 'sine.out' }, delay: 0.4 });

    function createDesktopTl() {

      gsap.to('.hero-anim-decorations > *', {
        y: 'random([-70, -150, -50])',
        ease: 'none',
        opacity: 0,
        scrollTrigger: {
          trigger: '.c-hero',
          start: 0,
          end: 'bottom top',
          scrub: 1,
        },
      });
  
      
      desktopTl.to(
        q('.hero-anim-white, .hero-anim-fade'),
        {
          duration: 1,
          opacity: 0,
          ease: 'none',
        },
        0.3
      )
        .to(
          q('.hero-anim-color'),
          {
            duration: 2,
            opacity: 1,
            ease: 'none',
          },
          0.3
        )
        .fromTo(
          q('.hero-anim-draw-center'),
          { drawSVG: '50% 50%' },
          { drawSVG: '0% 100% ', duration: 2.5 },
          1
        )
        .fromTo(
          q('.hero-anim-draw-right'),
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100% ', duration: 2.5 },
          1
        )
        .from(q('.hero-anim-draw-left'), { drawSVG: '0% 0%', duration: 2.5 }, 1)
        .to(q('.hero-anim-video'), { opacity: 1 }, 1)
        .fromTo(
          q('.hero-anim-draw-plant'),
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100% ', duration: 2 },
          1.5
        )
        .from(
          q('.hero-anim-draw-plant-2'),
          { drawSVG: '100% 100%', ease: 'none' },
          2
        )
        .from(
          q('.hero-anim-leaf-2'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'right bottom',
            rotate: 50,
          },
          2
        )
        .from(
          q('.hero-anim-leaf'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'left bottom',
            rotate: 50,
          },
          3
        )
        .from(
          q('.hero-anim-leaf-3'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'right bottom',
            rotate: -50,
          },
          3.2
        )
        .to(q('.hero-anim-with'), { opacity: 1, duration: 1 }, 2)
        .to(q('.hero-anim-vitaly'), { opacity: 1, duration: 1 }, 2.5)
        .to(
          q('.hero-anim-munch'),
          { opacity: 0, duration: 0.1, stagger: { from: 'end', each: 0.3 } },
          3
        )
        .from(
          q('.hero-anim-sparkles > *'),
          { opacity: 0, scale: 0.5, duration: 0.3, stagger: 0.2 },
          3.5
        )
        .from(
          q('.hero-anim-draw-fish'),
          { drawSVG: '100% 100%', duration: 2, ease: 'sine.out' },
          2
        )
        .from(
          q('.hero-anim-fish'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'center',
          },
          2
        )
        .to(
          q('.hero-anim-fishcake'),
          {
            transformOrigin: 'center',
            rotate: 360,
            duration: 1.5,
          },
          3
        )
        .to(
          q('.hero-anim-pepper, .hero-anim-salt'),
          {
            transformOrigin: 'center',
            ease: 'bottleShake',
            rotate: 30,
            yPercent: -5,
            xPercent: -8,
            stagger: 0.2,
            duration: 1,
          },
          3.8
        )
        .fromTo(
          q('.hero-anim-salts > *'),
          {
            y: -2,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.2,
            stagger: 0.04,
          },
          4
        )
        .to(
          q('.hero-anim-feeler'),
          {
            transformOrigin: 'left top',
            ease: 'prawnWiggle',
            rotate: 20,
            stagger: 0.5,
            duration: 4,
          },
          3.3
        );
    }
    function createMobileTl(){

      mobileTl.to(
        q('.hero-anim-white, .hero-anim-fade'),
        {
          duration: 1,
          opacity: 0,
          ease: 'none',
        },
        0.3
      )
        .to(
          q('.hero-anim-color'),
          {
            duration: 0.5,
            opacity: 1,
            ease: 'none',
          },
          0.3
        )
        .to(q('.hero-anim-video'), { opacity: 1, duration: 1 }, 0.5)
        .fromTo(
          q('.hero-anim-draw-plant'),
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100% ', duration: 2 },
          0.5
        )
        .from(
          q('.hero-anim-leaf-2'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'right bottom',
            rotate: 50,
          },
          1
        )
        .from(
          q('.hero-anim-leaf'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'left bottom',
            rotate: 50,
          },
          1
        )
        .to(q('.hero-anim-with'), { opacity: 1, duration: 1 }, 2.5)
        .to(q('.hero-anim-vitaly'), { opacity: 1, duration: 1 }, 3)
        .from(
          q('.hero-anim-sparkles > *'),
          { opacity: 0, duration: 0.3, stagger: 0.1 },
          2
        )
        .from(
          q('.hero-anim-draw-fish'),
          { drawSVG: '100% 100%', duration: 1, ease: 'none' },
          1.5
        )
        .from(
          q('.hero-anim-fish'),
          {
            scale: 0.3,
            opacity: 0,
            transformOrigin: 'center',
          },
          1.5
        )
        .to(
          q('.hero-anim-fishcake'),
          {
            transformOrigin: 'center',
            rotate: 360,
            duration: 1.5,
          },
          2
        )
        .to(
          q('.hero-anim-pepper, .hero-anim-salt'),
          {
            transformOrigin: 'center',
            ease: 'bottleShake',
            rotate: 30,
            duration: 0.4,
          },
          1.5
        )
        .to(
          q('.hero-anim-salts > *'),
          {
            opacity: 1,
            stagger: 0.1,
            duration: 0.1,
          },
          1.7
        )
        .to(
          q('.hero-anim-feeler'),
          {
            transformOrigin: 'left top',
            ease: 'prawnWiggle',
            rotate: 20,
            stagger: 0.5,
            duration: 4,
          },
          2
        );
    }

    function addMQListener(mq, callback) {
      if (mq.addEventListener) {
        mq.addEventListener("change", callback);
      } else {
        mq.addListener(callback);
      }
    }
    
    addMQListener(window.matchMedia("(min-width: 1000px)"),
      event => {
        if (event.matches) {
          if(desktopTl.totalDuration() === 0) {
            q = gsap.utils.selector(dt);
            createDesktopTl()
            desktopTl.totalProgress(1).pause()
          }
        } else {
          if(mobileTl.totalDuration() === 0) {
            q = gsap.utils.selector(mob);
            createMobileTl()
            mobileTl.totalProgress(1).pause()
          }
        }
      }
    );

    if (window.matchMedia('(min-width: 1000px)').matches) {
      createDesktopTl()
    } else {
      createMobileTl()
    }
  } else {
    gsap.set('.hero-anim-color', { opacity: 1 });
    
    let tl = gsap.timeline({ defaults: { ease: 'sine.out' } });
      tl.to(q('.hero-anim-video'), { opacity: 1 }, 0.3)
      .to(q('.hero-anim-with'), { opacity: 1, duration: 1 }, 0.8)
      .to(q('.hero-anim-vitaly'), { opacity: 1, duration: 1 }, 1.5);
  }
}

export default AnimHero;
