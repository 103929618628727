import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimHand() {
  const hand = document.querySelector('.anim-hand');
  if (hand) {
    const q = gsap.utils.selector('.anim-hand');

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.anim-hand',
        start: 'top 70%',
        end: 'top 40%',
        scrub: 1,
      },
    });

    tl.from(q('.lines > *'), {
      opacity: 0,
      scale: 0.2,
      transformOrigin: 'bottom center',
    })
      .from(q('.sparks > *'), {
        opacity: 0,
        scale: 0.2,
        transformOrigin: 'center',
      })
      .from(
        q('.hand'),
        {
          yPercent: 50,
        },
        0
      );
  }

  const handCta = document.querySelector('.anim-hand-cta');
  if (handCta) {
    const q = gsap.utils.selector('.anim-hand-cta');

    let tl = gsap.timeline({ paused: true });

    tl.from(q('.lines > *'), {
      opacity: 0,
      scale: 0.2,
      duration: 0.2,
      transformOrigin: 'bottom center',
    })
      .from(
        q('.sparks > *'),
        {
          opacity: 0,
          duration: 0.05,
          scale: 0.2,
          transformOrigin: 'center',
          stagger: 0.03,
          ease: 'sine.out(1.7)',
        },
        0
      )
      .from(
        q('.hand'),
        {
          yPercent: 50,
          duration: 0.8,
          ease: 'back.out(2)',
        },
        0
      );

    const button = document.querySelector('.anim-hand-btn');

    button.addEventListener('mouseover', (e) => {
      tl.play();
    });

    button.addEventListener('mouseleave', (e) => {
      tl.reverse();
    });
  }
}

export default AnimHand;
