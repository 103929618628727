import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimCreator() {
  const section = document.querySelector('.anim-creator');

  if (!section) return;

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.anim-creator',
      start: 'top 60%',
      end: 'top 30%',
    },
  });

  tl.from('.anim-creator-image', {
    opacity: 0,
    y: 80,
    duration: 6,
    ease: 'circ.out',
  }, 0);
}

export default AnimCreator;
