import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function AnimBite() {
  const animContainer = document.querySelector(".anim-bite");
  if (!animContainer) return;

  let mm = gsap.matchMedia();

  mm.add(
    {
      animIsOk: "(prefers-reduced-motion: no-preference)",
    },
    () => {
      gsap.set(".fooditem", { opacity: 0 });
      let buffet = gsap.utils.toArray(".fooditem");
      let snack = gsap.utils.random(buffet);

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: animContainer,
          start: "center 80%",
        },
      });

      tl.from(".sparks > *", {
        opacity: 0,
        duration: 0.3,
        scale: 0.2,
        transformOrigin: 'center',
        stagger: 0.2,
      })
      .to(snack, { opacity: 1, duration: 0.6 }, '<')
      .from(
        ".munch",
        {
          opacity: 0,
          duration: 0.01,
          stagger: 0.4,
        },
        "-=0.2"
      )
    },
    animContainer
  );
}

export default AnimBite;
