import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { CustomWiggle } from 'gsap/CustomWiggle';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, CustomEase, CustomWiggle);

function AnimBowl() {
  const animationIsOk = window.matchMedia(
    '(prefers-reduced-motion: no-preference)'
  ).matches;
  const bowl = document.querySelector('#SVGBowl');
  if (!bowl) return;

  CustomWiggle.create('bottleShake', {
    wiggles: 5,
    type: 'easeInOut',
  });

  let q = gsap.utils.selector('#SVGBowl');

  let previous;

  window.addEventListener('accordianClick', (e) => {
    gsap.set(q(`.item`), { opacity: 1 });
    const { open, food } = e.detail;
    if (previous === food && open === 'true') {
      return;
    }
    previous = food;

    gsap.to(q(`#${food}`), { opacity: 0, duration: 0.2 });

    if (animationIsOk) {
      switch (food) {
        case 'bottle':
          bottleTween.restart();
          break;
        case 'beetroot':
          beetrootTween.restart();
          break;
        case 'leaf':
          leafTween.restart();
          break;
        case 'ravioli':
          ravioliTween.restart();
          break;
        case 'fishcake':
          fishcakeTween.restart();
          break;
      }
    }
  });

  let bottleTween = gsap.to(q('.bottle'), {
    transformOrigin: 'center bottom',
    rotation: 10,
    duration: 0.6,
    ease: 'bottleShake',
    paused: true,
  });

  let beetrootTween = gsap.to(q('.beetroot'), {
    transformOrigin: 'center',
    rotate: 360,
    duration: 0.6,
    paused: true,
  });

  let leafTween = gsap.to(q('.leaf'), {
    transformOrigin: 'center bottom',
    yPercent: -10,
    yoyo: true,
    repeat: 1,
    duration: 0.4,
    ease: 'back.in(1.2)',
    paused: true,
  });

  let ravioliTween = gsap.to(q('.ravioli'), {
    transformOrigin: 'center',
    rotate: -20,
    scale: 1.03,
    yoyo: true,
    repeat: 1,
    duration: 0.6,
    ease: 'back.in(1.3)',
    paused: true,
  });

  let fishcakeTween = gsap.to(q('.fishcake'), {
    transformOrigin: 'center',
    rotate: 360,
    duration: 1,
    ease: 'sine',
    paused: true,
  });

  let mm = gsap.matchMedia();

  mm.add('(prefers-reduced-motion: no-preference)', () => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#SVGBowl',
        start: 'top 60%',
        end: 'bottom top',
      },
      timeScale: 1.5,
    });

    tl.to(q('.white'), {
      duration: 1,
      opacity: 0,
    })
      .fromTo(
        q('#ray-mask'),
        { drawSVG: '100% 100%' },
        { drawSVG: '0% 100%', duration: 2 },
        0
      )
      .from(
        q('#sparks > *'),
        {
          opacity: 0,
          scale: 0.5,
          transformOrigin: 'center',
          stagger: 0.1,
        },
        0
      )
      .from(
        q('#bubbles > *'),
        {
          opacity: 0,
          scale: 0.5,
          transformOrigin: 'center',
          stagger: 0.05,
        },
        0
      )
      .from(
        '.bowl-cta',
        {
          duration: 0.5,
          opacity: 0,
          transformOrigin: 'center',
          ease: 'back.out(1.7)',
        },
        1.5
      );
  });

  mm.add('(prefers-reduced-motion: reduce)', () => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#SVGBowl',
        start: 'top 60%',
        end: 'bottom center',
      },
    });

    tl.to(q('.white'), {
      duration: 1,
      opacity: 0,
    });
  });
}

export default AnimBowl;
