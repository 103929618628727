import { debounce, throttle } from './utils';

let articleTagsParent;
let articleTagsToggle;

function ArticleTags() {
  articleTagsParent = document.querySelector('.c-article-tags ul');
  articleTagsToggle = document.querySelector('.c-article-tags button');
  if (!articleTagsParent || !articleTagsToggle) return;

  articleTagsToggle.addEventListener('click', ToggleTags);

  articleTagsParent.classList.remove('c-article-tags--loading');

  SetupArticleTags();
  window.addEventListener('resize', throttle(SetupArticleTags, 300));
  window.addEventListener('resize', debounce(SetupArticleTags, 300));
}

function SetupArticleTags() {
  // Reset the parent width
  articleTagsParent.style.width = 'auto';

  // Remove child classes
  document.querySelectorAll('.c-article-tags__final').forEach((child) => {
    child.classList.remove('c-article-tags__final');
  });

  // Force a layout recalc
  const parentSize = articleTagsParent.getBoundingClientRect();
  let cutoff = parentSize.width - 85;

  const children = articleTagsParent.children;
  const firstLineHeight = children[0].offsetTop;

  // Get all items on the first line and not passed the button cutoff
  const firstLineItems = [...children].filter((child) => {
    if (firstLineHeight !== child.offsetTop) return false;
    return child.getBoundingClientRect().right - parentSize.left <= cutoff;
  });

  // If there's only one line of tags, hide the toggle
  articleTagsToggle.classList.toggle(
    'c-article-tags-trigger--hidden',
    firstLineItems.length === children.length
  );

  // Find the final item
  const finalItem = firstLineItems[firstLineItems.length - 1];
  const finalItemSizes = finalItem.getBoundingClientRect();

  // Mark the final item to add a push away margin
  finalItem.classList.add('c-article-tags__final');

  // Position the button
  articleTagsToggle.style.left = `calc(${
    finalItemSizes.right - parentSize.left
  }px - 15px + var(--space-xs))`;
  
  /* Adding -15px due to a hacky trick/workaround */
}

function ToggleTags() {
  const openClass = 'c-article-tags--open';
  articleTagsParent.classList.toggle(openClass);
  articleTagsToggle.textContent = articleTagsParent.classList.contains(
    openClass
  )
    ? 'Less...'
    : 'More...';
}

export default ArticleTags;
