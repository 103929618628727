function Accordion() {
  const accordion = Array.from(document.querySelectorAll('.c-accordion'));
  accordion.forEach(setupAccordion);
}

function setupAccordion(accordion) {
  const controls = accordion.querySelectorAll('.c-accordion__trigger');
  const children = accordion.children;

  controls.forEach((control) => {
    control.addEventListener('click', controlClick(children));
  });
}

function controlClick(children) {
  return (event) => {
    let detail = {
      open: event.currentTarget.getAttribute('aria-expanded'),
      food: event.currentTarget.dataset.food,
    };
    window.dispatchEvent(new CustomEvent('accordianClick', { detail }));

    const parent = event.currentTarget.parentNode;
    const content = parent.querySelector('.c-accordion__content');
    if (!content) return;

    const CLASSNAME = 'c-accordion--open';
    const wasOpen = parent.classList.contains(CLASSNAME);

    children.forEach((child) => {
      child.classList.remove(CLASSNAME);
    });

    parent.classList.toggle(CLASSNAME, !wasOpen);

    if (!wasOpen) {
      content.setAttribute('tabIndex', -1);
      content.focus();
      event.currentTarget.setAttribute('aria-expanded', true);
    } else {
      content.removeAttribute('tabIndex');
      event.currentTarget.setAttribute('aria-expanded', false);
    }
  };
}

export default Accordion;
