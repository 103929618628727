const HEIGHT_CUTOFF = 330;
const CLOSED_TOGGLE = 'Show more ↓';
const OPEN_TOGGLE = 'Show less ↑';
const CLOSED_HEIGHT = `${HEIGHT_CUTOFF}px`;
const MAX_TESTIMONIALS_VISIBLE = 12

function Testimonials() {
  const control = document.querySelector('.c-testimonials .c-testimonial__toggle');

  if (!control) return;
  control.classList.add('c-testimonial__toggle--active');

  toggleTestimonials();
  
  control.addEventListener('click', toggleTestimonials)
}

function toggleTestimonials(event) {
  const ul = document.querySelector('.c-testimonials__quotes')  
  const control = document.querySelector('.c-testimonials .c-testimonial__toggle')
  const lis = ul.querySelectorAll('.c-testimonials__quotes li')

  if (ul.classList.contains('.c-testimonials__quotes--collapsed')) {
    ul.classList.add('.c-testimonials__quotes--expanded')
  } {
    ul.classList.add('.c-testimonials__quotes--collapsed')
  }

  const isClosed = control.textContent === CLOSED_TOGGLE

  lis && Array.from(lis).forEach((el, i) => {
    if (i >= MAX_TESTIMONIALS_VISIBLE) el.classList.toggle('c-testimonials__quote--hidden')
  })
  control.textContent = isClosed ? OPEN_TOGGLE : CLOSED_TOGGLE;

  if (event && !isClosed) {
    setTimeout(() => {
      control.scrollIntoView({ block: 'center', behavior: 'auto' });
    }, 100);
  }

  if (event && isClosed && lis.length) {
    lis[MAX_TESTIMONIALS_VISIBLE].querySelector('a').focus();
  }
}

export default Testimonials;
