import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimNewsletter() {
  const svg = document.querySelector('#newsletter-svg');
  if (!svg) return;

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '#newsletter-svg',
      start: 'top 80%',
      end: 'bottom top',
      toggleActions: 'play reset pause reverse',
    },
  });
  tl.from(
    '.newsletter-letter',
    {
      yPercent: 100,
      duration: 1,
      ease: 'back.out(3)',
    },
    0.5
  )
    .from(
      '.newsletter-open',
      {
        scaleY: 0,
        transformOrigin: 'center',
        duration: 0.2,
        ease: 'none',
      },
      0
    )
    .from(
      '.newsletter-line',
      {
        opacity: 0,
        scale: 0,
        duration: 0.2,
        ease: 'back.out(3)',
        transformOrigin: 'bottom center',
      },
      1
    )
    .from(
      '.newsletter-sparkles',
      {
        opacity: 0,
        duration: 0.2,
        transformOrigin: 'center',
      },
      '<'
    );
}

export default AnimNewsletter;
