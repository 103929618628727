import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

let currentModal;
let currentTrigger;
let currentScrollPosition = null;

function Modal() {
  const modals = Array.from(document.querySelectorAll('.c-modal'));
  modals.forEach(setupModal);
  
  window.addEventListener('keydown', e => onKeydown(e, modals));
}

function setupModal(modal) {
  const id = modal.getAttribute('id');
  const triggers = document.querySelectorAll('[href="#' + id + '"]');

  Array.from(triggers).forEach(trigger => setupModalTrigger(trigger, modal));
  Array.from(modal.querySelectorAll('.c-modal__close,.c-modal__wing,.c-header')).forEach(close => setupCloseTrigger(close, modal, triggers[0]));
}

function setupCloseTrigger(close, modal, trigger) {
  close.addEventListener('click', (e) => {
    e.preventDefault();
    closeModal(modal, trigger);
  });
}

function onPopState(e) {
  e.preventDefault();
  closeModal(currentModal, currentTrigger);
}

function setupModalTrigger(trigger, modal) {
  trigger.addEventListener('click', (e) => {
    e.preventDefault();
    modal.classList.add('c-modal--open');
    modal.setAttribute('tabIndex', '-1');

    currentScrollPosition = window.scrollY;
    
    disableBodyScroll(modal);

    currentModal = modal;
    currentTrigger = trigger;
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', onPopState);

    const video = modal.querySelector('iframe');
    if (video) {
      if (video.getAttribute('src').includes('player.vimeo')) {
        const player = new Vimeo.Player(video);
        player.play();
      } else {
        video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      }
    }

    setTimeout(() => {
      const focusId = trigger.getAttribute('data-focus');
      const focusEl = document.getElementById(focusId);

      if (focusEl) {
        focusEl.setAttribute('tabIndex', '-1');
        focusEl.focus();
      } else {
        modal.focus();
      }
    }, 100);
  });
}

function onKeydown(e, modals) {
  if (e.key === 'Escape') {
    modals.forEach(modal => {
      if (modal.classList.contains('c-modal--open')) {
        const trigger = document.querySelector('[href="#' + modal.getAttribute('id') + '"]');
        closeModal(modal, trigger);
      }
    })
  }
}

function closeModal(modal, trigger) {
  modal.classList.remove('c-modal--open');
  modal.removeAttribute('tabIndex');
  document.documentElement.classList.add('no-smooth-scroll');

  const video = modal.querySelector('iframe');
  if (video) {
    if (video.getAttribute('src').includes('player.vimeo')) {
      const player = new Vimeo.Player(video);
      player.pause();
    } else {
      video.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    }
  }

  window.removeEventListener('popstate', onPopState);

  trigger && trigger.focus();
  window.location.hash = trigger.id;
  setTimeout(() => {
    window.scrollTo(0, currentScrollPosition);
    history.replaceState(null, document.title, window.location.pathname);
    enableBodyScroll(modal);
  }, 1);


  setTimeout(() => {
    document.documentElement.classList.remove('no-smooth-scroll');
  }, 100);
}

export default Modal;
