import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { CustomWiggle } from 'gsap/CustomWiggle';

gsap.registerPlugin(ScrollTrigger, CustomWiggle, CustomEase);

function AnimBits() {
  const sparks = document.querySelectorAll('.c-icon--spark');
  if (sparks.length) {
    gsap.from(sparks, {
      opacity: 0,
      scale: 0,
      transformOrigin: 'center',
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.anim-slat',
        start: 'top 60%',
      },
    });
  }

  const bits = document.querySelectorAll('.anim-decoration');

  if (bits.length) {
    gsap.set(bits, {
      yPercent: 400,
    });

    gsap.to(bits, {
      yPercent: 'random([-200, -500, -800])',
      ease: 'none',
      opacity: 0,
      scrollTrigger: {
        trigger: '.anim-packages',
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
      },
    });
  }

  const slide = document.querySelector('.glide__slide--active');

  if (slide) {
    gsap.from(slide, {
      yPercent: 40,
      duration: 1,
      ease: 'sine.out',
      scrollTrigger: {
        trigger: slide,
        start: 'top 90%',
      },
    });
  }

  const bottleSVG = document.querySelector('#anim-bottle');

  if (bottleSVG) {

    CustomWiggle.create('bottleShake', {
      wiggles: 8,
      type: 'easeInOut',
    });

    let tl = gsap.timeline({scrollTrigger: {
      trigger: bottleSVG,
      start: 'top 90%',
    }})
    tl.from(
      '#dotted-line',{
        opacity: 0
      }
    )
    .from(
      '#bottle-spark > *',{
        opacity: 0,
        stagger: 0.1
      }
    )
    .to('#bottle-jar', {
      transformOrigin: 'center bottom',
      rotation: 10,
      duration: 1.2,
      ease: 'bottleShake',
    }, 0);
  }

  
}

export default AnimBits;
