import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function AnimTea() {
  const svg = document.querySelector('#svg-tea');
  if (!svg) return;

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: svg,
      start: 'top 80%',
      end: 'top top',
      scrub: 2,
    },
  });
  tl.from('.biscuit', {
    rotate: 360,
    transformOrigin: 'center',
    xPercent: 150,
    onStart: () => {
      gsap.to('.teabag', {
        rotate: -30,
        duration: 0.2,
        ease: 'none',
        transformOrigin: 'top center',
      });
      gsap.to('.teabag', {
        rotate: 0,
        duration: 20,
        delay: 0.2,
        ease: 'elastic.out( 1.5, 0.1)',
        transformOrigin: 'top center',
      });
    },
  });
}

export default AnimTea;
