function Beats() {

  if (!_beats) return;

  const localBeats = getLocalBeats();

  _beats.forEach(({ slug, count }) => {
    let localBeat = localBeats.find(x => x.slug === slug);
    if (!localBeat) {
      localBeat = { slug, count };
      localBeats.push(localBeat);
    }
    
    if (localBeat.count < count) {
      localBeat.count = count;
    }

    updateLocalBeatElements(localBeat);
  });

  setLocalBeats(localBeats);

  document.querySelectorAll('[data-add-beat]').forEach(el => {
    el.addEventListener('click', AddBeat);
  });

}

function getLocalBeats() {
  return JSON.parse(localStorage.getItem('_beats') || '[]');
}

function setLocalBeats(beats) {
  localStorage.setItem('_beats', JSON.stringify(beats));
}

function updateLocalBeatElements(localBeat) {
  document
    .querySelectorAll(`[data-beat-id="/articles/${localBeat.slug}/"]`)
    .forEach(el => {
      el.textContent = `${localBeat.count} Beat${localBeat.count === 1 ? '' : 's'}`;
    });
}

function AddBeat(event) {
  const url = event.currentTarget.dataset.addBeat;
  const localBeats = getLocalBeats();
  const localBeat = localBeats.find(x => `/articles/${x.slug}/` === url);
  if (!localBeat) return;
  localBeat.count++;
  
  //anim-beats.js
  event.target.querySelector('.anim-beats').tl.restart()

  try {
    if (_beatsAPI) {
      fetch(`${_beatsAPI}/beats/${localBeat.slug}`, {
        method: 'POST'
      });
    }
  } catch (error) {
    
  }

  updateLocalBeatElements(localBeat);

  setLocalBeats(localBeats);
}

export default Beats;
