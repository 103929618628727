import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function AnimNoodles(toggle) {
  const animContainer = document.querySelector(".anim-noodles");
  if (!animContainer) return;

  let mm = gsap.matchMedia();

  mm.add(
    {
      animIsOk: "(prefers-reduced-motion: no-preference)",
      animReduced: "(prefers-reduced-motion: reduce)",
    },
    () => {
      if (toggle.checked === false) return;

      let tl = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: animContainer,
          start: "top 80%",
        },
      });

      gsap.set(".chopstick", {
        rotation: 30,
        y: -300,
        x: 600,
        transformOrigin: "center left",
      });
      gsap.set(".noodle", {
        x: 300,
        y: 300,
      });

      tl.add(noodleTl().timeScale(0.7))
        .from(
          ".peas-dots > *",
          {
            opacity: 0,
            duration: 1.2,
            stagger: {
              from: "random",
              amount: 1,
            },
          },
          1.5
        )
        .from(
          ".falling > *",
          {
            rotation: "random([-360, -360])",
            y: -700,
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
            ease: "back.out(0.7)",
            transformOrigin: "center center",
          },
          2
        )
        .from(
          ".sparks > *",
          {
            y: -100,
            opacity: 0,
            duration: 1.2,
            stagger: {
              from: "random",
              amount: 1,
            },
          },
          0.5
        );

      function noodleTl() {
        let tl = gsap.timeline({
          defaults: {
            ease: "sine.out",
            duration: 0.33,
          },
        });

        tl.to(".chopstick", {
          rotation: 0,
          x: 100,
          y: 100,
        })
          .to(".noodle, .chopstick", {
            x: "-=100",
            y: "-=100",
          })
          .to(
            ".chopstick",
            {
              rotation: 15,
              transformOrigin: "center left",
            },
            "<"
          )
          .to(".chopstick", {
            rotation: 0,
            y: 80,
            ease: "sine.inOut",
          })
          .to(
            ".chopstick",
            {
              x: 80,
              ease: "circ.out",
            },
            "<"
          )
          .to(".noodle, .chopstick", {
            x: "-=100",
            y: "-=100",
          })
          .to(
            ".chopstick",
            {
              rotation: 15,
              transformOrigin: "center left",
            },
            "<"
          )
          .to(".chopstick", {
            y: 40,
            rotation: 0,
            ease: "sine.inOut",
          })
          .to(
            ".chopstick",
            {
              x: 40,
              ease: "circ.out",
            },
            "<"
          )
          .to(".chopstick", {
            x: "-=100",
            y: "-=100",
            duration: 1,
          })
          .to(
            ".chopstick",
            {
              rotation: 15,
              transformOrigin: "center left",
            },
            "<"
          )
          .to(
            ".noodle",
            {
              x: 0,
              y: 0,
              duration: 1,
            },
            "<"
          )
          .to(".chopstick", {
            rotation: 0,
            x: 0,
            y: 0,
            ease: "back.out",
            duration: 1,
          });

        return tl;
      }
    },
    animContainer
  );
}

export default AnimNoodles;
