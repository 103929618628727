import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function AnimCatch() {
  const animContainer = document.querySelector(".anim-chopsticks");
  if (!animContainer) return;

  let mm = gsap.matchMedia();

  mm.add(
    "(prefers-reduced-motion: no-preference)",
    () => {
      let tl = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: animContainer,
          // markers: true,
          start: "top 80%",
        },
      });

      tl.from(".chopstick", {
        xPercent: 100,
        yPercent: -100,
        ease: "back.out",
        rotation: -30,
        transformOrigin: "center right",
      })
        .from(
          ".falling > *",
          {
            rotation: "random([-360, -360])",
            y: -700,
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
            ease: "back.out(0.7)",
            transformOrigin: "center center",
          },
          0
        )
        .from(
          ".fishcake",
          {
            rotation: 700,
            y: -700,
            opacity: 0,
            duration: 1,
            ease: "power2.in",
            transformOrigin: "center center",
          },
          0.5
        )
        .from(
          ".sparks > *",
          {
            y: -100,
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
          },
          0.5
        )
        .from(
          ".peas-dots > *",
          {
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
          },
          0.5
        )
        .to(
          ".food",
          {
            keyframes: {
              yPercent: [0, 10, 0],
              rotation: [0, -10, 0],
            },
            ease: "back.out",
            transformOrigin: "center right",
          },
          1.45
        );
    },
    animContainer
  );
}

export default AnimCatch;
