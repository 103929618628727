function LessonAccordion() {
  const accordion = Array.from(document.querySelectorAll('[data-lesson-accordions]'));
  accordion.forEach(setupLessonAccordion);
}

function setupLessonAccordion(accordion) {
  const controls = accordion.querySelectorAll('[data-lesson-accordion] [aria-controls]');
  const children = accordion.querySelectorAll('[data-lesson-accordion] [aria-labelledby]');

  controls.forEach((control) => {
    control.addEventListener('click', accordionClick);
  });

  if (controls.length && children.length) {
    toggleAccordion(controls[0], children[0]);
  }
}

function accordionClick(event) {
  toggleAccordion(event.currentTarget, event.currentTarget.nextElementSibling);
}

function toggleAccordion(trigger, child) {
  const isOpen = trigger.getAttribute('aria-expanded') === 'true';
  trigger.setAttribute('aria-expanded', isOpen ? 'false' : 'true');

  if (isOpen) {
    child.setAttribute('hidden', 'hidden');
  } else {
    child.removeAttribute('hidden');
  }
}

export default LessonAccordion;
