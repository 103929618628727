const inView = new Set();
const notInView = new Set();
let selectedElement = null;

function Toc() {
  const toc = document.querySelector('.toc ol');
  if (!toc) return;
  
  SetupToc(toc);
}

function SetupToc(toc) {
  let observer = new IntersectionObserver(tocCallback);

  toc.children.forEach(child => {
    const heading = document.getElementById(child.children[0].getAttribute('href').replace('#', ''));
    if (heading) {
      observer.observe(heading);
    }
  });
}

function tocCallback(entries, observer) {
  entries.forEach(entry => {
    const link = document.querySelector(`.toc a[href="#${entry.target.id}"]`);
    if (!link) return;

    if (entry.isIntersecting) {
      inView.add(link);
      notInView.delete(link);
    } else {
      inView.delete(link);
      notInView.add(link);
    }
  });

  const inViewArray = [...inView];

  if (inViewArray.length === 0) {
    if (!selectedElement) {
      // Make the first link active
      [...notInView][0].setAttribute('aria-current', 'step');
    } else {
      // Leave it be
    }
  } else if (inViewArray.length === 1) {
    // Make it active
    inViewArray[0].setAttribute('aria-current', 'step');
    selectedElement = inViewArray[0];
    // Deactivate the rest
    [...notInView].forEach(el => el.removeAttribute('aria-current'));
  } else {
    // Make the last active
    inViewArray.forEach((el, index, array) => {
      if (index === array.length - 1) {
        el.setAttribute('aria-current', 'step');
        selectedElement = el;
      } else {
        el.removeAttribute('aria-current')
      }
    });

    // Deactivate the rest
    [...notInView].forEach(el => el.removeAttribute('aria-current'));
  }
  

}

export default Toc;
