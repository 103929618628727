import { throttle, debounce } from './utils';

function Slider() {
  const sliders = Array.from(document.querySelectorAll('.c-lessons__slides'));
  sliders.forEach(setupSlider);
}

const LESSON_WIDTH = 263;
const LESSON_GAP = 36;

function onScroll(slider, controls, counter) {
  let isMaximumScrollCache = false;
  let isMinimumScrollCache = true;
  const children = slider.children.length;
  const childPositions = Array.from(slider.children).map(
    (child) => child.offsetLeft
  );

  return () => {
    const maxScrollWidth = slider.scrollWidth - slider.clientWidth;
    const [nearestIndex, position] = childPositions.reduce(
      (prev, pos, index) => {
        const distance = Math.abs(pos - slider.scrollLeft);
        return distance <= prev[1] ? [index, distance] : prev;
      },
      [0, 9999]
    );

    counter.innerText = `${Math.min(nearestIndex + 1, children)} | ${children}`;

    const isMaximumScroll = slider.scrollLeft === maxScrollWidth;
    if (isMaximumScroll && !isMaximumScrollCache) {
      controls[1].classList.add('fade-out');
      isMaximumScrollCache = true;
    } else if (!isMaximumScroll && isMaximumScrollCache) {
      controls[1].classList.remove('fade-out');
      isMaximumScrollCache = false;
    }

    const isMinimumScroll = slider.scrollLeft === 0;
    if (isMinimumScroll && !isMinimumScrollCache) {
      controls[0].classList.add('fade-out');
      isMinimumScrollCache = true;
    } else if (!isMinimumScroll && isMinimumScrollCache) {
      controls[0].classList.remove('fade-out');
      isMinimumScrollCache = false;
    }
  };
}

function setupSlider(el) {
  const slider = el.querySelector('.c-lessons__slider ol');
  const controls = el.querySelectorAll('.c-lessons__controls button');
  const counter = el.querySelector('.c-lessons__count');

  Array.from(controls).forEach((control) => {
    control.addEventListener('click', function () {
      const isForwards = control.dataset.direction === '+';
      slider.scrollBy({
        top: 0,
        left: (LESSON_WIDTH + LESSON_GAP * 2) * (isForwards ? 1 : -1),
        behavior: 'smooth',
      });
    });
  });

  const scrollEvent = onScroll(slider, controls, counter);
  scrollEvent();
  slider.addEventListener('scroll', throttle(scrollEvent, 200));
  slider.addEventListener('scroll', debounce(scrollEvent, 200));
}

export default Slider;
