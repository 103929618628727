import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function AnimPasta(toggle) {
  const animContainer = document.querySelector(".anim-pasta");
  if (!animContainer) return;

  let mm = gsap.matchMedia();

  mm.add(
    {
      animIsOk: "(prefers-reduced-motion: no-preference)",
      animReduced: "(prefers-reduced-motion: reduce)",
    },
    () => {
      if (toggle.checked === false) return;

      let tl = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: animContainer,
          start: "80% bottom",
        },
      });

      tl.from(".arm", {
        xPercent: 100,
        ease: "back.out",
        rotation: -10,
        transformOrigin: "center right",
      })
        .from(
          ".hand",
          {
            ease: "back.out",
            rotation: -10,
            transformOrigin: "center right",
          },
          0.2
        )
        .from(
          ".falling > *",
          {
            rotation: "random([-360, -360])",
            y: -700,
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
            ease: "back.out(0.7)",
            transformOrigin: "center center",
          },
          0
        )
        .from(
          ".peas-dots > *",
          {
            y: -100,
            opacity: 0,
            duration: 1,
            stagger: {
              from: "random",
              amount: 1,
            },
          },
          0
        )
        .from(
          ".pasta",
          {
            y: -700,
            opacity: 0,
            duration: 0.2,
          },
          1
        )
        .to(
          ".hand, .arm",
          {
            ease: "back.out",
            keyframes: {
              rotation: [0, -5, 0],
            },
            duration: 1.2,
            ease: "back.out",
            transformOrigin: "center right",
          },
          1.17
        );

      tl.timeScale(0.9);
    },
    animContainer
  );
}

export default AnimPasta;
