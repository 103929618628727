import { throttle, debounce } from './utils';

let currentColumns = 0;
let count = 0;
let children = [];
let tabTriggers = [];
let tabs = [];
let el;

function Rack() {
  el = document.querySelector('.c-lessons__rack');
  if (!el) return;

  children = Array.from(el.children);
  count = children.length;

  const lessons = Array.from(el.querySelectorAll('.c-lesson'));
  if (!count) return;

  lessons[0].classList.add('c-lesson--active');

  el.addEventListener('click', onClick);

  onResize();
  window.addEventListener('resize', throttle(onResize, 500));
  window.addEventListener('resize', debounce(onResize, 500));

  tabTriggers = Array.from(
    document.querySelectorAll('.c-view-tab__controls a')
  );

  tabTriggers.forEach((el) => {
    el.addEventListener('click', onTabClick);
  });

  tabs = Array.from(document.querySelectorAll('.c-view-tab'));
}

function onTabClick(e) {
  e.preventDefault();
  const id = e.currentTarget.getAttribute('href').replace('#', '');
  tabs.forEach((tab) => {
    if (tab.id === id) {
      tab.classList.add('c-view-tab--active');
    } else {
      tab.classList.remove('c-view-tab--active');
    }
  });

  tabTriggers.forEach((trigger) => {
    if (e.currentTarget === trigger) {
      trigger.setAttribute('aria-expanded', true);
    } else {
      trigger.removeAttribute('aria-expanded', true);
    }
  });
}

function onClick(e) {
  const { target } = e;
  if (target.nodeName !== 'BUTTON') return;
  e.preventDefault();

  const active = el.querySelector('.c-lesson--active');
  if (active) active.classList.remove('c-lesson--active');
  target.parentNode.children[0].classList.add('c-lesson--active');
}

function onResize() {
  const { innerWidth } = window;
  let columnCount;
  if (innerWidth < 610) {
    columnCount = 1;
  } else if (innerWidth < 920) {
    columnCount = 2;
  } else if (innerWidth < 1240) {
    columnCount = 3;
  } else {
    columnCount = 4;
  }

  if (columnCount === currentColumns) return;

  currentColumns = columnCount;
  const rows = Math.ceil(count / currentColumns);
  let html = '';

  let index = 0;
  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    let rowHtml = '<div>';
    for (let columnIndex = 0; columnIndex < currentColumns; columnIndex++) {
      const child = children[index];
      if (child) {
        rowHtml += child.outerHTML;
      }

      index++;
    }
    rowHtml += '</div>';
    html += rowHtml;
  }

  el.innerHTML = html;
}

export default Rack;
