let observer = window.ResizeObserver ? new ResizeObserver(onResize) : null;

function InlineInput() {
  const inlineInput = Array.from(document.querySelectorAll('.c-input-inline-button'));
  inlineInput.forEach(el => {
    observer && observer.observe(el);
  });
}

function onResize(entries) {
  entries.forEach(({ target, contentRect }) => {
    target.classList.toggle('c-input-inline-button--wide', contentRect.width > 450)
  });
}

export default InlineInput;
