import { gsap } from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

function AnimBeats() {
  
  const animBeats = document.querySelector(".anim-beats");
  if (!animBeats) return;
  animBeats.hasBeenLiked = false

  animBeats.parentElement.addEventListener('mouseenter', () => {
    gsap.to('.anim-beats .anim-heart', {
      fill: '#ee7868',
      duration: 0.5
    })
  })

  animBeats.parentElement.addEventListener('mouseleave', () => {
    if(animBeats.hasBeenLiked) return;
    gsap.to('.anim-beats .anim-heart', {
      fill: '#00000000',
      duration: 0.5
    })
  })

  animBeats.tl = gsap.timeline({paused: true, defaults: {duration: 1}});

  animBeats.tl.to('.anim-beats .anim-heart', {
    fill: '#ee7868',
    duration: 0.5,
    onStart: () => animBeats.hasBeenLiked = true
  }, 0)
  .to('.anim-beats .pretzel-heart', {
    scale: 1.6,
    transformOrigin: '52% 55%',
    duration: 1,
    ease: 'elastic.out(1, 0.3)'
  },0)
  .to('.circle-pretzel', {
    opacity: 0,
    transformOrigin: 'center',
    duration: 1,
    ease: 'back.out'
  }, 0)
  .from('.anim-beats .dots', {
    opacity: 0,
    duration: 0.5
  }, 0)
  .from('.anim-beats .sparks > *', {
    opacity: 0,
    scale: 0,
    transformOrigin: 'center center',
    duration: 0.5
  }, 0)
  .from('.anim-beats .lines > *', {
    drawSVG: '0%',
    duration: 0.5
  }, 0)
  .to('.anim-beats .lines > *', {
    drawSVG: '100% 100%',
    opacity: 0,
    duration: 0.5
  }, 0.5)
  .to('.anim-beats .dots', {
    opacity: 0,
    duration: 0.5
  }, 0.5)
  .to('.anim-beats .sparks > *', {
    opacity: 0,
    scale: 0,
    transformOrigin: 'center center',
    duration: 0.5
  }, 0.5)

  animBeats.tl.progress(animBeats.dataset.animState)
}

export default AnimBeats;
